import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import AppIcon from '@/components/app/AppIcon.vue'
import AppFilterSelector from '@/components/app/AppFilterSelector'
import SelectionField from '@/components/general/SelectionField'
import OrderRating from '@/components/page/orders/OrderRating'
import { PageModule } from '@/store/page'
import { CreateElement } from 'vue/types/umd'
import { filter } from 'rxjs/operators'
import { OrderItemResponsibleUsers, OrderItem, HeaderItem, OrderItemInspector } from '@/models/page'
import dayjs from 'dayjs'
import { WebsocketEvent, WebsocketPayload } from '@/models'
import { Debounce } from '@/utils/helper'

const defaultShowRows = ['issueId', 'serviceType', 'status', 'serviceTitle', 'customer', 'buildingTitle', 'responsibleUsers', 'updatedAt', 'rating', 'extra']

@Component({
  components: {
    AppIcon,
    OrderRating,
    SelectionField,
    AppFilterSelector
  }
})
export default class OrdersTable extends Vue {
  @Prop({ type: String, default: null }) search!: string
  @Prop({ type: Object, required: true }) query!: Record<string, string>
  @Prop({ type: Boolean, default: true }) isAll!: boolean

  currentTimestampUnix = dayjs(new Date()).unix()
  sortId: null | string = null
  sortType: null | string = null
  isLoading = false
  alignHeaders = 'left'
  showRows = JSON.parse(localStorage.getItem(`order${this.isAll ? 'All' : 'New'}Rows`) || JSON.stringify(defaultShowRows))

  get finalHeaders() {
    localStorage.setItem(`order${this.isAll ? 'All' : 'New'}Rows`, JSON.stringify(this.showRows))

    return this.headers.reduce<HeaderItem[]>((acc, header) => {
      if (this.showRows.includes(header.value)) {
        acc.push(header)
      }

      return acc
    }, [])
  }

  headers: HeaderItem[] = [{
    value: 'issueId',
    align: this.alignHeaders,
    text: '№',
    cellClass: 'ws-nowrap',
    sortable: true
  }, {
    value: 'extId',
    align: this.alignHeaders,
    cellClass: 'w-5',
    text: 'Внешний номер заявки',
    sortable: true
  }, {
    value: 'serviceType',
    align: this.alignHeaders,
    text: 'Тип',
    sortable: true
  }, {
    value: 'status',
    align: this.alignHeaders,
    text: 'Статус',
    sortable: true
  }, {
    value: 'serviceTitle',
    align: this.alignHeaders,
    text: 'Услуга',
    sortable: true
  }, {
    value: 'orderSourceTitle',
    align: this.alignHeaders,
    text: 'Канал обращения',
    sortable: true
  }, {
    value: 'customer',
    align: this.alignHeaders,
    text: 'Клиент / Адрес',
    width: '200',
    sortable: true
  }, {
    value: 'buildingTitle',
    align: this.alignHeaders,
    text: 'Объект',
    sortable: true
  }, {
    value: 'placeNumber',
    align: this.alignHeaders,
    text: 'Номер помещения',
    sortable: true
  }, {
    value: 'placeEntranceNumber',
    align: this.alignHeaders,
    text: 'Подъезд',
    sortable: true
  }, {
    value: 'placeFloor',
    align: this.alignHeaders,
    text: 'Этаж',
    sortable: true
  }, {
    value: 'customerSummary',
    align: this.alignHeaders,
    text: 'Суть обращения',
    cellClass: 'ws-pre-line',
    sortable: false
  }, {
    value: 'responsibleUsers',
    align: this.alignHeaders,
    text: 'Исполнители',
    cellClass: 'ws-pre-line',
    sortable: false
  }, {
    value: 'inspectors',
    align: this.alignHeaders,
    text: 'Контролёры'
  }, {
    value: 'acceptedOrderUser',
    align: this.alignHeaders,
    text: 'Заявку принял',
    sortable: false
  }, {
    value: 'closeOrderUser',
    align: this.alignHeaders,
    text: 'Заявку закрыл',
    sortable: false
  }, {
    value: 'managerSubGroup',
    align: this.alignHeaders,
    text: 'Управляющий кварталом',
    sortable: false
  }, {
    value: 'performersAllStatuses',
    align: this.alignHeaders,
    text: 'Исполнители (Все)',
    sortable: false
  }, {
    value: 'historyStatus',
    align: this.alignHeaders,
    text: 'История',
    sortable: false
  }, {
    value: 'updatedAt',
    align: this.alignHeaders,
    text: 'Обновлено',
    cellClass: 'ws-nowrap',
    sortable: true
  }, {
    value: 'rating',
    align: this.alignHeaders,
    text: 'Оценка',
    sortable: true
  }, {
    value: 'extra',
    align: this.alignHeaders,
    text: 'Метки',
    sortable: true
  }, {
    value: 'lastOrderComment',
    align: this.alignHeaders,
    text: 'Комментарий',
    sortable: true
  }]

  dict = {
    ratingOptions: [
      { id: 0, title: 'Без оценки' },
      { id: 1, title: '1' },
      { id: 2, title: '2' },
      { id: 3, title: '3' },
      { id: 4, title: '4' },
      { id: 5, title: '5' }
    ],
    reactionTimeOptions: [
      { id: 1, title: 'Время реакции - вовремя' },
      { id: 2, title: 'Время выполнения - вовремя' },
      { id: 3, title: 'Время реакции - просрочено' },
      { id: 4, title: 'Время выполнения - просрочено' }
    ]
  }

  tab = 'all'

  get finalQuery() {
    const query = Object.assign({}, this.query)

    if (this.sortId && this.sortType) {
      query.order = this.sortId + ''
      query.sort = this.sortType + ''
    }
    query.searchQuery = this.search

    return query
  }

  @Debounce()
  @Watch('finalQuery', { deep: true })
  UpdateTable(value: string, oldValue: string) {
    if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
      this.loadOrders()
    }
  }

  get orders() {
    return PageModule[this.isAll ? 'allOrders' : 'newOrders']
  }

  async mounted() {
    await this.loadOrders(true)
    const handleEvents: WebsocketEvent[] = [
      WebsocketEvent.NEW_CALL,
      WebsocketEvent.ORDER_NEW,
      WebsocketEvent.CHAT_MESSAGE,
      WebsocketEvent.ORDER_COMMENT_NEW,
      WebsocketEvent.ORDER_STATUS_CHANGE
    ]
    const filterSocket = filter<WebsocketPayload>((value) => handleEvents.includes(value.name))

    this.$socket.pipe(filterSocket).subscribe((event) => {
      this.loadOrders()
    })

    window.addEventListener('focus', this.handleFocusTab)
  }

  beforeDestroy() {
    window.removeEventListener('focus', this.handleFocusTab)
  }

  @Debounce()
  async loadOrders(loadMore = false) {
    this.isLoading = true
    await PageModule.getOrders({ loadMore, query: this.finalQuery, isAll: this.isAll })
    this.isLoading = false
  }

  async loadMoreOrders(entries: Array<IntersectionObserverEntry>) {
    if (entries[0].isIntersecting && !this.isLoading && this.orders.nextRow !== -1) {
      await this.loadOrders(true)
    }
  }

  handleFocusTab() {
    this.loadOrders()
  }

  customSort(items: OrderItem[], index: string[], isDesc: boolean[]) {
    const sortId = index[0]
    const sort = isDesc[0] ? 'desc' : 'asc'
    const isDiff = this.sortId !== sortId || this.sortType !== sort

    if (!isDiff) return items

    this.sortId = sortId
    this.sortType = sort

    return items
  }

  render(h: CreateElement) {
    return <div>
      {
        h('v-menu', {
          props: {
            'offset-y': true,
            'close-on-content-click': false
          },
          scopedSlots: {
            activator: ({ on, attrs }: any) => {
              return h('v-btn', {
                props: {
                  dark: true,
                  'x-small': true,
                  ...attrs
                },
                on
              }, 'Настроить столбцы')
            }
          }
        },
        [
          <v-list>
            {
              this.headers.map((header) => {
                return h('v-checkbox', {
                  props: {
                    dense: true,
                    'hide-details': true,
                    label: header.text,
                    value: header.value,
                    inputValue: this.showRows
                  },
                  class: ['mt-0'],
                  on: {
                    change: (value: string[]) => {
                      this.showRows = value
                    }
                  }
                })
              })
            }
          </v-list>
        ])
      }
      { h('v-data-table', {
        props: {
          headers: this.finalHeaders,
          items: this.orders.items,
          'hide-default-footer': true,
          'disable-pagination': true,
          customSort: this.customSort
        },
        class: {
          'table--loading': this.isLoading
        },
        on: {
          'click:row': (order: OrderItem) => {
            window.open(order.urlToTenant)
          }
        },
        scopedSlots: {
          footer: () => {
            return <div v-intersect={ this.loadMoreOrders }></div>
          },
          'item.issueId': ({ value }: { value: string }) => {
            return <small>
              <strong>
                <SelectionField text={ value + '' } selection={this.search} />
              </strong>
            </small>
          },
          'item.serviceType': ({ item }: { item: OrderItem }) => {
            return <v-img class="service-type-image" src={item.serviceTypeImage}></v-img>
          },
          'item.serviceTitle': ({ value }: { value: string }) => {
            return <SelectionField text={ value } selection={ this.search }/>
          },
          'item.status': ({ item }: { item: OrderItem }) => {
            return <v-chip color={ item.orderStatusColor } text-color="black"> { item.orderStatusTitle } </v-chip>
          },
          'item.customer': ({ item }: { item: OrderItem }) => {
            return <div>
              <SelectionField text={ item.customerShortName } selection={ this.search } />
              { item.customerStatus ? <small> ({ item.customerStatus }) </small> : null }
              <br/>
              { item.customerPhoneNumber || this.$options.filters?.phoneFormat(item.unregisteredPhoneNumber) || '-' }
              { item.placeAddress ? [<br/>, <SelectionField text={ item.placeAddress } selection={ this.search } />] : null }
              { item.customerDebt > 0
                ? [<br/>, <small> долг <span class="text-error"> { this.$options.filters?.moneyFormat(item.customerDebt) }</span></small>]
                : null }
            </div>
          },
          'item.responsibleUsers': ({ value }: { value: OrderItemResponsibleUsers[] }) => {
            return value.map((user: any) => `${user.firstName} ${user.lastName}`).join(',\n') || '-'
          },
          'item.inspectors': ({ value }: { value: OrderItemInspector[] }) => {
            return value.map((inspector: OrderItemInspector) => `${inspector.firstName} ${inspector.lastName}`).join(',\n') || '-'
          },
          'item.historyStatus': ({ value }: { value: string }) => {
            return [
              h('v-tooltip',
                {
                  props: {
                    left: true,
                    tag: 'div',
                    'content-class': 'tooltip'
                  },
                  scopedSlots: {
                    activator: ({ on, attrs }: any) => {
                      return h('v-chip', {
                        props: {
                          color: 'primary',
                          small: true,
                          ...attrs
                        },
                        on
                      }, 'Показать')
                    }
                  }
                },
                [<span> { value } </span>]
              )
            ]
          },
          'item.updatedAt': ({ item }: { item: OrderItem }) => {
            return item.updatedAt
              ? <small>{ this.$options.filters?.dateTime(item.updatedAt) }</small>
              : <small>{ this.$options.filters?.dateTime(item.createdAt) }</small>
          },
          'item.rating': ({ value }: { value: number }) => {
            return <OrderRating value={ value }/>
          },
          'item.extra': ({ value, item }: { value: number; item: OrderItem }) => {
            const viewSLA = item.reactionTimeSLA !== null && ((item.reactionTimeFact !== null && item.reactionTimeFact > item.reactionTimeSLA) || (item.reactionTimeFact === null && item.reactionTimeSLA < this.currentTimestampUnix))
            const viewSLAWrench = item.solveTimeSLA !== null && ((item.solveTimeFact !== null && item.solveTimeFact > item.solveTimeSLA) || (item.solveTimeFact === null && item.solveTimeSLA < this.currentTimestampUnix))
            let paidNode

            if (item.creditSum > 0 && item.paidSum === 0 && item.invoiceStatusId === 2) {
              paidNode = <v-chip
                class="mt-1 ml-1"
                text-color="black"
                color="yellow"
                small
                title="счет не оплачен"
              >
                { this.$options.filters?.moneyFormat(item.creditSum) }
              </v-chip>
            } else if (item.creditSum > 0 && item.paidSum === 0) {
              paidNode = <v-chip
                class="mt-1"
                text-color="black"
                color="secondary"
                small
                title="счет не выставлен"
              >
                { this.$options.filters?.moneyFormat(item.creditSum) }
              </v-chip>
            } else if (item.creditSum > 0 && item.totalSum === 0 && item.isPaidByLK) {
              paidNode = <v-chip
                class="mt-1"
                text-color="black"
                color="success"
                small
                title="счет оплачен"
              >
                { this.$options.filters?.moneyFormat(item.paidSum) }
              </v-chip>
            } else if (item.creditSum > 0 && item.totalSum === 0 && !item.isPaidByLK) {
              paidNode = <v-chip
                class="mt-1"
                text-color="black"
                color="success"
                small
                title="счет оплачен"
              >
                { this.$options.filters?.moneyFormat(item.paidSum) }
              </v-chip>
            } else if (item.creditSum > 0 && item.totalSum === 0 && !item.isPaidByLK) {
              paidNode = <v-chip
                class="mt-1"
                text-color="black"
                color="info"
                small
                title="Счет отмечен оплаченным"
              >
                { this.$options.filters?.moneyFormat(item.paidSum) }
              </v-chip>
            }

            return [
              viewSLA ? <v-icon title="Нарушен SLA времени реакции" color="red" small> mdi-clock-outline </v-icon> : null,
              viewSLAWrench ? <v-icon title="Нарушен SLA времени выполнения" color="red" small> mdi-wrench </v-icon> : null,
              Number(item.userWarning) !== 0 ? <v-chip small class="mt-1">
                <v-icon color="white" small left> mdi-comment-alert </v-icon>
                { item.userWarning }
              </v-chip> : null,
              Number(item.unviewedCommentsCount) !== 0 ? <v-chip color="red" small class="mt-1">
                <v-icon color="white" small left> mdi-chat-question </v-icon>
                { item.unviewedCommentsCount }
              </v-chip> : null,
              item.new ? <v-chip small color="yellow" text-color="black" class="mt-1"> Новая </v-chip> : null,
              item.isAccident ? <v-chip small color="red" text-color="black" class="mt-1"> Авария </v-chip> : null,
              paidNode
            ]
          }
        }
      }) }
    </div>
  }
}
